import { default as attachedlist0F9LZwt3ZLMeta } from "/home/ecs-user/smart-platform-test/pages/attachedlist.vue?macro=true";
import { default as dashboardMbcXH8rjweMeta } from "/home/ecs-user/smart-platform-test/pages/dashboard.vue?macro=true";
import { default as equipmentbKJBic3IoYMeta } from "/home/ecs-user/smart-platform-test/pages/equipment.vue?macro=true";
import { default as indexAtFiHIZwCTMeta } from "/home/ecs-user/smart-platform-test/pages/index.vue?macro=true";
import { default as inputdatahGwXd9ujNEMeta } from "/home/ecs-user/smart-platform-test/pages/inputdata.vue?macro=true";
import { default as inspectresulth94tMDbHXlMeta } from "/home/ecs-user/smart-platform-test/pages/inspectresult.vue?macro=true";
import { default as mandatoryparamS3yqsWBOTTMeta } from "/home/ecs-user/smart-platform-test/pages/mandatoryparam.vue?macro=true";
import { default as metricWHUgPSceGxMeta } from "/home/ecs-user/smart-platform-test/pages/metric.vue?macro=true";
import { default as metricdatac6BQ3gYRr2Meta } from "/home/ecs-user/smart-platform-test/pages/metricdata.vue?macro=true";
import { default as positionusuxasuxlCMeta } from "/home/ecs-user/smart-platform-test/pages/position.vue?macro=true";
import { default as repairtaskIUMKFxNJ2tMeta } from "/home/ecs-user/smart-platform-test/pages/repairtask.vue?macro=true";
import { default as roleF14BGnllmPMeta } from "/home/ecs-user/smart-platform-test/pages/role.vue?macro=true";
import { default as siteyfPWq4h6dzMeta } from "/home/ecs-user/smart-platform-test/pages/site.vue?macro=true";
import { default as sparepartRiQRofwcr6Meta } from "/home/ecs-user/smart-platform-test/pages/sparepart.vue?macro=true";
import { default as table8pefvoqRZeMeta } from "/home/ecs-user/smart-platform-test/pages/table.vue?macro=true";
import { default as tableviewZnd6VrMhu6Meta } from "/home/ecs-user/smart-platform-test/pages/tableview.vue?macro=true";
export default [
  {
    name: "attachedlist",
    path: "/attachedlist",
    component: () => import("/home/ecs-user/smart-platform-test/pages/attachedlist.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/ecs-user/smart-platform-test/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "equipment",
    path: "/equipment",
    component: () => import("/home/ecs-user/smart-platform-test/pages/equipment.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ecs-user/smart-platform-test/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inputdata",
    path: "/inputdata",
    component: () => import("/home/ecs-user/smart-platform-test/pages/inputdata.vue").then(m => m.default || m)
  },
  {
    name: "inspectresult",
    path: "/inspectresult",
    component: () => import("/home/ecs-user/smart-platform-test/pages/inspectresult.vue").then(m => m.default || m)
  },
  {
    name: "mandatoryparam",
    path: "/mandatoryparam",
    component: () => import("/home/ecs-user/smart-platform-test/pages/mandatoryparam.vue").then(m => m.default || m)
  },
  {
    name: "metric",
    path: "/metric",
    component: () => import("/home/ecs-user/smart-platform-test/pages/metric.vue").then(m => m.default || m)
  },
  {
    name: "metricdata",
    path: "/metricdata",
    component: () => import("/home/ecs-user/smart-platform-test/pages/metricdata.vue").then(m => m.default || m)
  },
  {
    name: "position",
    path: "/position",
    component: () => import("/home/ecs-user/smart-platform-test/pages/position.vue").then(m => m.default || m)
  },
  {
    name: "repairtask",
    path: "/repairtask",
    component: () => import("/home/ecs-user/smart-platform-test/pages/repairtask.vue").then(m => m.default || m)
  },
  {
    name: "role",
    path: "/role",
    component: () => import("/home/ecs-user/smart-platform-test/pages/role.vue").then(m => m.default || m)
  },
  {
    name: "site",
    path: "/site",
    component: () => import("/home/ecs-user/smart-platform-test/pages/site.vue").then(m => m.default || m)
  },
  {
    name: "sparepart",
    path: "/sparepart",
    component: () => import("/home/ecs-user/smart-platform-test/pages/sparepart.vue").then(m => m.default || m)
  },
  {
    name: "table",
    path: "/table",
    component: () => import("/home/ecs-user/smart-platform-test/pages/table.vue").then(m => m.default || m)
  },
  {
    name: "tableview",
    path: "/tableview",
    component: () => import("/home/ecs-user/smart-platform-test/pages/tableview.vue").then(m => m.default || m)
  }
]