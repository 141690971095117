<template>
  <Body>
    <NuxtLoadingIndicator />
    <NuxtPage />
    <NuxtErrorBoundary />
  </Body>
</template>

<script setup lang="ts">
import '@mdi/font/css/materialdesignicons.min.css'
import 'vuetify/styles'
import { createVuetify, useTheme } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { en, zhHans } from 'vuetify/locale'
import { VTreeview } from 'vuetify/labs/VTreeview'

const vuetify = createVuetify({
  ssr: true,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: { mdi },
  },
  locale: {
    locale: 'zhHans',
    fallback: 'en',
    messages: { zhHans, en },
  },
  components: {
    VTreeview,
  },
})
const nuxtApp = useNuxtApp()
nuxtApp.vueApp.use(vuetify)
const theme = useTheme()
theme.themes.value.light.colors.background = '#ddd'
</script>

<style>
.bg-primary ::selection {
  background-color: rgba(255, 255, 255, .1);
}
</style>
